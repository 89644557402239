<template>
    <div :style="alt ? 'height: 100%;' : ''" v-if="loading == false">
        <img :src="source" :alt="alt" :id="'file-' + fileId" v-if="src" />
        <div v-if="alt && !src" class="company-logo">
            <div>
                {{ alt }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DynamicImage',
        props: {
            alt: {
                type: String,
                required: true,
                // default: 'No Image',
                default: '',
            },
            fileId: {},
            placeholder: {
                type: String,
            },
            kind: {
                type: String,
            },
            user: {
                type: Object,
            },
            client: {
                type: Object,
            },
            urlToRetrieveFile: {
                type: String,
            },
            //Expects a file
            localData: {},
            storeKey: {
                type: String
            },
            storeFileId: {
                type: [Number, String]
            }
        },
        data() {
            return {
                src: null,
                loading: true
            };
        },
        // inject: ['storeFileId'],
        created() {
            if (!this.storeKey) {
                this.retrieveFile();
            }
            if (this.storeKey) {
                this.src = this.$store.getters[this.storeKey];
                this.loading = false;
                this.$emit('getById', this.storeFileId);
            }
        },
        methods: {
            async retrieveFile() {
                delete this.client?.pages;
                delete this.user?.pages;
                if (this.urlToRetrieveFile) {
                    let response = await this.$api.get(this.urlToRetrieveFile, {
                        params: {
                            fileId: this.fileId,
                            kind: this.kind,
                            client: JSON.stringify(this.client),
                            user: JSON.stringify(this.user),
                        },
                    });
                    this.src = response?.data?.file?.Body ? `data:image;base64, ${response?.data?.file.Body}` : null;
                    //No matter what request you do, please always return a fileId.
                    this.$emit('getById', response?.data?.file?.id);
                }
                this.loading = false;
            },
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
        },
        computed: {
            source() {
                if (this.src) {
                    return this.src;
                } else if (!this.src && this.placeholder) {
                    return this.placeholder;
                }
                return null;
            },
        },
        watch: {
            fileId: function () {
                this.retrieveFile();
            },
            
            localData: async function (file) {               
                this.src =  await this.getBase64(file);
            },
            storeKey: function (newVal) {
                this.src = this.$store.getters[newVal];
            },
            urlToRetrieveFile() {
                this.retrieveFile();
            },
        },
    };
</script>