<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <table>
                        <tbody>
                            <tr>
                                <td><b>DUI Evaluation:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.dui_evaluation_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.dui_evaluation" @currency="handleCurrencyChange($event, 'dui_evaluation')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>DUI Evaluation Update:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.dui_evaluation_update_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.dui_evaluation_update" @currency="handleCurrencyChange($event, 'dui_evaluation_update')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>Treatment Needs Assessment:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.treatment_needs_assessment_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.treatment_needs_assessment" @currency="handleCurrencyChange($event, 'treatment_needs_assessment')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>10-Hrs DUI Risk Education:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.ten_hrs_dui_risk_education_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.ten_hrs_dui_risk_education" @currency="handleCurrencyChange($event, 'ten_hrs_dui_risk_education')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>12-Hrs Early Intervention Services:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.twelve_hrs_early_intervention_services_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.twelve_hrs_early_intervention_services" @currency="handleCurrencyChange($event, 'twelve_hrs_early_intervention_services')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>Moderate Risk DUI Services:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.moderate_risk_dui_services_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.moderate_risk_dui_services" @currency="handleCurrencyChange($event, 'moderate_risk_dui_services')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>Significant Risk DUI Services:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.significant_risk_dui_services_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.significant_risk_dui_services" @currency="handleCurrencyChange($event, 'significant_risk_dui_services')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>High Risk DUI Services:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.high_risk_dui_services_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.high_risk_dui_services" @currency="handleCurrencyChange($event, 'high_risk_dui_services')"/>
                                </div></b></td>
                            </tr>
                            <tr>
                                <td><b>Continuing Care/Aftercare Sessions:</b></td>
                                <td><b><div class="flex">
                                    <Checkbox v-model="rec.continuing_care_aftercare_sessions_cb" class="flex-1 top-10"/>
                                    <CurrencyInput :initial-value="rec.continuing_care_aftercare_sessions" @currency="handleCurrencyChange($event, 'continuing_care_aftercare_sessions')"/>
                                </div></b></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="avoid-break">
                        <p><b>If you are indigent per the US Department of Health & Human Services poverty guidelines,  Rogerian Solutions will provide a DUI Evaluation and Risk Education Services free of charge. Rogerian Solutions will assist you with determining if you are indigent and methods you can demonstrate your indigence to receive a free DUI Evaluation and Risk  Education Services.</b></p>
                        <p><b>If needed, Payment plans are available and will be created according to your financial abilities.</b></p>
                        <p><b>No client will be permitted to attend two consecutive sessions after he or she is delinquent on a scheduled payment.</b></p>
                        <p><b>My signature indicates that I have received a copy of this Fee Schedule.</b></p>
                    </div>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import DynamicImage from '@/components/general/image/Image';
    import { companies, file } from '@/util/apiRequests';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'
    import CurrencyInput from "@/components/general/inputs/CurrencyInput";

    export default {
        name: 'FeeScheduleRogerian',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, DynamicImage, FormCompany, FormSignatureButton, SignConvertPdfModal, CurrencyInput },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
                file
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
            handleCurrencyChange(newCurrencyTotal, field) {
                this.$set(this.rec, field, this.$getNumFromCurrency(newCurrencyTotal));
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>
